import { Label, Input } from "reactstrap";

export default function PeskaTransfer(props) {
  const {
    t, setIsFirstStepValid, setPaymentPayload
  } = props;

  const onChangeHandler = (e) => {
    if (e.target.value) {
      setPaymentPayload({
        email: e.target.value,
        gateway: "PESKA"
      });
      setIsFirstStepValid(true);
    } else {
      setPaymentPayload({});
      setIsFirstStepValid(false);
    }
  };

  return (
    <div>
      <p className="text-muted">{t("Add Your Peska Account")}</p>
      <div className="mb-3">
        <Label>{t("Peska Account ")}</Label>
        <Input
          type="email"
          name="email"
          onChange={onChangeHandler}
          required
          placeholder="Enter Your Peska Email"
        />
      </div>
    </div>
  );
}