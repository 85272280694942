/* eslint-disable no-debugger */
import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import {
  addDeposit,
  addForexDeposit,
  paymentGatewayDeposit,
} from "apis/deposit";
import {
  allowedMethods
} from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import AsiaBanks from "./Methods/AsiaBanks";
import APForm from "./Methods/AsiaBanksForm";
import { fetchCompanyBankAccounts } from "apis/bankAccounts";
import PerfectMoney from "./Methods/PerfectMoney";
import PMForm from "./Methods/PerfectMoneyForm";
import Loader from "components/Common/Loader";
import PeskaTransfer from "./Methods/Peska";
import {
  PESKA_CALLBACK_URL, PESKA_FAILURE_URL, PESKA_SUCCESS_URL 
} from "./constants";

function DepositStatus(props) {
  const {
    t, result,
    type, gateway, toggle, paymentPayload, activeStep
  } = props;

  // console.log("RESULT IN UI", result);
  const peskaFailedFrame = (
    <>
      <div className="text-center mb-4">
        <h1 className="fs-1 mb-5">
          {t("Oops!")} <i className="bx bx-sad"></i>
        </h1>
        <p>{t("Your Deposit Request Not Successfully Created")}</p>
        <p>
          <span className="text-danger">
            {result?.message !== "[object Object]"
              ? result?.message
              : t("An error occurred during payment processing")}
          </span>
        </p>
        {result?.result && (
          <p>
            <span className="text-danger">
              {result.result.amount} {result.result.currency}
            </span>
          </p>
        )}
      </div>
      <CardWrapper className="mb-4">
        <div className="d-flex align-items-center justify-content-around px-4">
          <div>
            <div className="text-muted">{t("Status")}</div>
            <div className="text-danger">{t(result?.result?.status || "FAILED")}</div>
          </div>
          <div>
            <div className="text-muted">{t("GATEWAY")}</div>
            <div>{result?.result?.gateway || gateway}</div>
          </div>
        </div>
        <div className="text-center mt-3">
          <Button
            className="btn btn-danger m-2 btn-sm w-lg"
            onClick={toggle}
          >
            {t("Try Again")}
          </Button>
        </div>
      </CardWrapper>
    </>
  );

  const peskaSuccessFrame = (
    <>
      <div className="text-center mb-4">
        <h1 className="fs-1 mb-5">
          {t("Yay!")} <i className="bx bx-wink-smile"></i>
        </h1>
        <p>{t("Pending Deposit Thank You")}</p>
        <p>
          <span className="positive">
            {result?.result?.amount} {result?.result?.currency}
          </span>
        </p>
        <div className="mt-3">
          <p className="text-muted mb-2">{t("Your Transaction ID")}</p>
          <p className="positive">
            {result?.result?.transactionId || result?.result?.sid?.data?.order_no}
          </p>
        </div>
      </div>
      <CardWrapper className="mb-4">
        <div className="d-flex align-items-center justify-content-around px-4">
          <div>
            <div className="text-muted">{t("Status")}</div>
            <div className="positive">{t("PENDING")}</div>
          </div>
          <div>
            <div className="text-muted">{t("GATEWAY")}</div>
            <div>{result?.result?.gateway || gateway}</div>
          </div>
        </div>
        {result?.result?.url && (
          <div className="text-center mt-3">
            <p className="text-muted">{t("You will be redirected to the payment page in 2 seconds...")}</p>
            <Loader />
          </div>
        )}
      </CardWrapper>
    </>
  );

  const successDeposit = (
    <div className="text-center mb-4">
      <h1 className="fs-1 mb-5">
        {t("Yay!")} <i className="bx bx-wink-smile"></i>
      </h1>
      <p>{t("Pending Deposit Thank You")}</p>
      <p>
        <span className="positive">
          {
            type === "fiatDeposit" ? result?.result?.amount?.$numberDecimal : result?.result?.amount
          }
          {" "}
          {
            type === "fiatDeposit" ? result?.result?.currency : result?.result?.currency
          }
        </span>
      </p>
      <span className="text-muted">
        {t("Your transaction ID is")} {result?.result?._id || result?.result?.sid?.data?.order_no}
      </span>
    </div>
  );

  const defaultFailedDeposit = (
    <>
      <div className="text-center mb-4">
        <h1 className="fs-1 mb-5">
          {t("Oops!")} <i className="bx bx-sad"></i>
        </h1>
        <p>{t("Your Deposit Request Not Successfully Created")}</p>
      </div>
      <CardWrapper className="mb-4">
        <div className="d-flex align-items-center justify-content-between px-5">
          <div>
            <div className="text-danger">{result.message}</div>
          </div>
        </div>
      </CardWrapper>
      <div className="text-center">
        <Button
          className="btn btn-danger m-2 btn-sm w-lg"
          onClick={toggle}
        >
          {t("Continue")}
        </Button>
      </div>
    </>
  );

  const renderPeskaFrame = () => {
    if (result?.status) {
      return peskaSuccessFrame;
    }
    return peskaFailedFrame;
  };

  useEffect(() => {
    let redirectTimer;
    if (result?.result?.url && activeStep === 2) {
      redirectTimer = setTimeout(() => {
        window.open(result.result.url, "_blank", "noopener,noreferrer");
        toggle(); // Close the modal after redirection
      }, 2000);
    }
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [result, activeStep, toggle]);

  return (
    <>
      {gateway === "ASIA_BANKS" && result.status && <APForm result={result} />}
      {gateway === "PERFECT_MONEY" && result.status && (
        <PMForm
          result={{
            ...result.result,
            payeeAccount: paymentPayload.accountNo,
          }}
        />
      )}
      {gateway === "PESKA" && renderPeskaFrame()}
      {gateway !== "ASIA_BANKS" &&
        gateway !== "PERFECT_MONEY" &&
        gateway !== "PESKA" && (
        <>
          {result.status ? (
            <>
              {successDeposit}
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-around px-4">
                  <div>
                    <div className="text-muted">{t("Status")}</div>
                    <div className="positive">{t(result?.result?.status)}</div>
                  </div>
                  <div>
                    <div className="text-muted">{t("GATEWAY")}</div>
                    <div>{gateway}</div>
                  </div>
                </div>
              </CardWrapper>
            </>
          ) : (
            defaultFailedDeposit
          )}
        </>
      )}
      {(gateway === "ASIA_BANKS" || gateway === "PERFECT_MONEY") && (
        <CardWrapper>
          <div className="align-items-center px-4 text-center">
            <div className="positive">
              {t("We are transferring you to the payment provider page.")}
            </div>
            <Loader />
          </div>
        </CardWrapper>
      )}
    </>
  );
}

// ----------------------------------------------------------
// ----------------------------------------------------------

function Deposit({ isOpen, toggleOpen, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  const { clientData } = useSelector(state => state.Profile);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [targetCurrency, setTargetCurrency] = useState("USD");
  const [localBanks, setLocalBanks] = useState([]);
  const [InternationalBanks, setInternationalBanks] = useState([]);

  useEffect(async () => {
    const banks = await fetchCompanyBankAccounts();
    if (banks.length > 0) {
      setInternationalBanks([...banks.filter((bank) => bank.isLocal === false)]);
      setLocalBanks([...banks.filter((bank) => bank.isLocal === true)]);
    }
  }, []);

  // Effects
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(getAccountsStart());
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  useEffect(() => {
    if (result?.result?.url) {
      const timer = setTimeout(() => {
        window.open(result.result.url, "_blank", "noopener,noreferrer");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [result]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} isLocal={false} banks={InternationalBanks} />
        );
      case "LOCAL_WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} isLocal={true} banks={localBanks} />
        );
      case "ASIA_BANKS":
        return (
          <AsiaBanks type={type} t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} />
        );

      case "OLX_FOREX":
        return (
          <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return (
          <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "CRYPTO":
        return (
          <CompanyCrypto
            t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload}
            setBaseCurrency={setBaseCurrency} />
        );
      case "PERFECT_MONEY":
        return (
          <PerfectMoney t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} />
        );
      case "PESKA":
        return (
          <PeskaTransfer t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload} />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">
              {t("Please Select Payment Method")}
            </h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
    if (data && data instanceof FormData && data.get("gateway") === "LOCAL_WIRE_TRANSFER") {
      data.delete("gateway");
      data.append("gateway", "WIRE_TRANSFER");
    }
    if (type === "fiatDeposit") {
      console.log("Submitted Data FROM DEPOSIT: ", data);
      if (
        selectedPaymentMethod === "OLX_FOREX"
        || selectedPaymentMethod === "ASIA_BANKS"
        || selectedPaymentMethod === "PERFECT_MONEY"
      ) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else if (selectedPaymentMethod === "PESKA") {
        const payload = {
          currency: targetCurrency,
          amount: data?.amount,
          email: data?.paymentPayload?.email,
          walletId: data?.walletId,
          success_url: PESKA_SUCCESS_URL,
          failure_url: PESKA_FAILURE_URL,
          callback_url: PESKA_CALLBACK_URL,
        };
        
        console.log("PESKA PAYLOAD: ", payload);
        paymentGatewayDeposit(payload, selectedPaymentMethod)
          .then((res) => {
            const resultData = {
              ...res,
              status: !res?.isError,
              result: {
                ...res?.result,
                gateway: "PESKA",
                status: !res?.isError ? "PENDING" : "FAILED",
                amount: payload.amount,
                currency: payload.currency
              }
            };
            setResult(resultData);
            toggleTab(2);
          })
          .catch((err) => {
            const errorResponse = err?.response?.data || err;
            const errorData = {
              status: false,
              code: errorResponse?.code || 500,
              message: errorResponse?.message || "Payment failed",
              isSuccess: false,
              isError: true,
              result: {
                gateway: "PESKA",
                status: "FAILED",
                amount: payload.amount,
                currency: payload.currency
              }
            };
            setResult(errorData);
            toggleTab(2);
          });
      } else {
        addDeposit(data).then((res) => {
          setLoading(false);
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setLoading(false);
          setResult(err);
          toggleTab(2);
        });
      }
    } else if (type === "mt5Deposit") {
      if (selectedPaymentMethod === "ASIA_BANKS"
        || selectedPaymentMethod === "PERFECT_MONEY"
      ) {
        data.currency = targetCurrency;
        paymentGatewayDeposit(data, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
        return;
      } else if (selectedPaymentMethod === "PESKA") {
        const payload = {
          currency: targetCurrency,
          amount: data?.amount,
          email: data?.paymentPayload?.email,
          tradingAccountId: data?.tradingAccountId,
          success_url: PESKA_SUCCESS_URL,
          failure_url: PESKA_FAILURE_URL,
          callback_url: PESKA_CALLBACK_URL,
        };

        console.log("FROM MT5 PESKA PAYLOAD: ", payload);
        paymentGatewayDeposit(payload, selectedPaymentMethod).then((res) => {
          setResult(res);
          toggleTab(2);
          if (res?.result?.url) {
            window.location.href = res?.result?.url;
          }
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      } else {
        addForexDeposit(data).then((res) => {
          setResult(res);
          toggleTab(2);
        }).catch((err) => {
          setResult(err);
          toggleTab(2);
        });
      }
    }
  };

  const wire_instructions = (
    <Row>
      <Col xs={12} className="text-center py-2">
        <h4>Notes</h4>
      </Col>
      <Col xs={12} className="py-2">
        <ol style={{ listStyleType: "decimal" }}>
          <li>International Wire Transfer processing times: 2-5 business days.</li>
          <li>We strongly advise that you deposit in the currency of your trading account so that
            your transaction does not incur additional conversion fees.
          </li>
          <li>Should your bank require a SWIFT code that is longer than 8 digits, please insert
            X&apos;s at the end of the XeOne Prime SWIFT code.
          </li>
          <li>While XeOne Prime does not normally charge you for deposits, IFX UK will charge
            an incoming receiving fee depending on the deposit amount. Outgoing transfer fees
            and fees from correspondent bank(s) might also be incurred. To receive the full
            amount of your bank wire transfer in your trading account, we recommend that you
            instruct your bank that all bank charges, including fees from correspondent bank(s),
            are paid by the remitter.
          </li>
          <li>Please note that we do not accept payments from third parties. Your bank wire
            transfer must be made from an account that is under the same name as your XeOne
            Prime account.</li>
          <li>We strongly suggest that you instruct your banker to include your User ID in Field
            70 of the Beneficiary Information section of your wire transfer instructions, so as to
            ensure that your funds are deposited into your account in a timely manner.
          </li>
          <li>Any charges, fees or penalties that result from failed, returned or refunded
            third-party transactions are the responsibility of the sender. XeOne Prime will not be
            held liable for any of the aforementioned charges. By proceeding with a transaction
            you agree to these Terms & Conditions.
          </li>
          <li>Payments are processed between Monday to Friday, 7:00 – 21:00 (GMT+2).

          </li>
        </ol>
      </Col>
    </Row>
  );

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">
                {t("Select Payment Method")}
              </h6>
              <Row className="justify-content-center payment-methods">
                {
                  allowedMethods.filter((method) => method.allowed.includes(type))
                    .filter((method) => {
                      if (method.isSandbox) {
                        return clientData?.email?.includes("mailinator.com");
                      }
                      return true;
                    })
                    .map((method) => {
                      if (!method.showForCountries || (method.showForCountries && method.showForCountries.includes(clientData.country)))
                        return <Col key={method.gateway} xs={4} lg={2} className="my-2">
                          <button
                            type="button"
                            onClick={() => {
                              setGateway(method.gateway);
                              setSelectedPaymentMethod(method.gateway);
                            }}
                            onMouseEnter={() => { setHoveredPaymentMethod(method.gateway) }}
                            onMouseLeave={() => { setHoveredPaymentMethod() }}
                            className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4 d-flex align-items-center justify-content-center`}
                            style={{
                              maxWidth: "80px",
                              maxHeight: "80px",
                            }}
                          >
                            <img
                              src={method.image}
                              width="100%"
                              height="100%"
                              alt=""
                            ></img>
                          </button>
                          <div className="mt-3">
                            {method.gateway === "ASIA_BANKS" && clientData.country === "India" && (
                              <p>UPI India Online</p>
                            )}
                            {method.gateway === "ASIA_BANKS" && clientData.country === "Malaysia" && (
                              <p>Online Bank MYR</p>
                            )}
                            {method.gateway === "ASIA_BANKS" && clientData.country === "Indonesia" && (
                              <p>Online Bank IDR</p>
                            )}
                            {method.gateway === "ASIA_BANKS" && clientData.country === "Philippines" && (
                              <p>Online Bank PHP</p>
                            )}
                            {method.gateway === "ASIA_BANKS" && (clientData.country === "Viet Nam" || clientData.country === "Vietnam") && (
                              <p>Online Bank VND</p>
                            )}
                          </div>
                        </Col>;
                    })
                }
              </Row>
            </div>
            {gateway && stageOnePaymentDetails()}
            {(gateway === "WIRE_TRANSFER") && wire_instructions}
            <div className="text-center mt-4">
              <Button
                type="submit"
                className="btn btn-success waves-effect waves-light w-lg btn-sm"
                disabled={((gateway === "") || !isFirstStepValid)}
                onClick={() => toggleTab(1)}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <StageTwo
          t={t}
          setLoading={setLoading}
          loading={loading}
          toggleTab={toggleTab}
          type={type}
          gateway={gateway}
          handleSubmit={submitHandler}
          paymentPayload={paymentPayload}
          receipt={allowedMethods.find((method) => method.gateway === gateway)?.receipt}
          targetCurrency={targetCurrency}
          baseCurrency={baseCurrency}
          setTargetCurrency={setTargetCurrency}
        />
      ),
    },
    {
      header: "Deposit status",
      content: (
        <DepositStatus
          t={t}
          toggle={toggleOpen}
          gateway={gateway}
          type={type}
          result={result}
          paymentPayload={paymentPayload}
          activeStep={activeStep}
        />
      ),
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>
  );
}

export default withTranslation()(Deposit);
