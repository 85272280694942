import config from "config";
import AsiaBanks from "assets/img/payment-method/asiabanks.png";
import PerfectMoney from "assets/img/payment-method/perfectmoney.png";
import { INT_COUNTRIES } from "helpers/countries";

export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
    showForCountries: [...INT_COUNTRIES]
  },
  {
    gateway: "LOCAL_WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/bank_local.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
    showForCountries: ["India"]
  },
  {
    gateway: "ASIA_BANKS",
    name: "Asia Banks",
    image: AsiaBanks,
    allowed: ["mt5Deposit", "fiatDeposit"],
    receipt: false,
    showForCountries: ["Malaysia", "India", "Indonesia", "Vietnam", "Viet Nam",  "Philippines"]
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
    receipt: false,
  },
  {
    gateway: "PERFECT_MONEY",
    name: "Perfect Money",
    image: PerfectMoney,
    receipt: false,
    isSandbox: true,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  },
  {
    gateway: "PESKA",
    name: "PESKA",
    image: "/img/payment-method/peska.png",
    receipt: false,
    //isSandbox: true,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],  
    showForCountries: [...INT_COUNTRIES] 
  }
];