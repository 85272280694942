import React, { useState, useEffect } from "react";
import {
  Container, Card, CardBody 
} from "reactstrap";
import { XCircle } from "react-feather";

const FailurePage = () => {
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          window.location.replace("/dashboard");
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Card className="overflow-hidden">
          <CardBody className="p-4">
            <div className="text-center">
              <XCircle className="text-danger" size={50} />
              <h3 className="mt-4 mb-3">Deposit Failed</h3>
              <p className="text-muted mb-4">There was an issue processing your deposit request.</p>
              <p className="text-muted mb-4">Redirecting to dashboard in {countdown} seconds...</p>
              <button
                onClick={() => window.location.replace("/dashboard")}
                className="btn btn-primary btn-sm w-md waves-effect waves-light"
              >
                Go to Dashboard
              </button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default FailurePage;
